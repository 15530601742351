.ctaButton {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: var(--FF-BORDER-RADIUS);
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #555;
  }
}
