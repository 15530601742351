.PromotionsForm {
  > div > div:not(:nth-last-child(2)) > div:first-child {
    width: fit-content;
    background-color: rgba(70, 0, 0, 0.08);
    border-radius: 10px;
  }

  h3, p {
    margin-bottom: 0 !important;
  }
  p {
    font-size: .8em;
  }

  label,
  section > input,
  section > textarea {
    display: block;
  }

  span {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
  }

  input[type="text"],
  textarea {
    padding: 8px;
    border: none;
    width: calc(100% - 20px);
  }

  label {
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 5px;
  }

  img {
    height: 1em;
    padding-right: 3px;
  }
}
