.CurrencyInput {
  position: relative;
}
.CurrencyInput:after {
  position: absolute;
  top: 0;
  content: "€";
  left: 1.5ch;
}
.CurrencyInput input[type="text"] {
  width: 10ch !important;
  padding-left: 2.5ch !important;
}

.CurrencyInput input:disabled {
  color: transparent;
}
