// TODO: Optimize so partner-specific fonts are only loaded for that specific partner
@font-face {
	font-family: 'Fivo Sans';
	src:
		local('FivoSans-Regular'),
		local('FivoSans-Heavy'),
		url('./assets/fonts/FivoSans-Regular.woff2') format('woff2'),
		url('./assets/fonts/FivoSans-Heavy.woff2') format('woff2');
	font-weight: normal, bold;
}

@font-face {
	font-family: 'Forma-DJR';
	src:
		local('FormaDJRBanner-Medium-Testing'),
		local('FormaDJRDeck-Bold-Testing'),
		url('./assets/fonts/FormaDJRBanner-Medium-Testing.woff2') format('woff2'),
		url('./assets/fonts/FormaDJRDeck-Bold-Testing.woff2') format('woff2');
	font-weight: normal, bold;
}

@font-face {
	font-family: 'Amsterdam Sans';
	src:
		local('AmsterdamSans-RegularB08'),
		local('AmsterdamSans-BoldB08'),
		url('./assets/fonts/AmsterdamSans-RegularB08.woff2') format('woff2'),
		url('./assets/fonts/AmsterdamSans-BoldB08.woff2') format('woff2');
	font-weight: normal, bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TT Teds';
	src:
		local('TTTedsRegular'),
		local('TTTedsBold'),
		url('./assets/fonts/TTTedsRegular.ttf') format('truetype'),
		url('./assets/fonts/TTTedsBold.ttf') format('truetype');
	font-weight: normal, bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Weissenhof Grotesk';
	src:
		local('WeissenhofGrotesk-Regular'),
		local('WeissenhofGrotesk-Bold'),
		url('./assets/fonts/WeissenhofGrotesk-Regular.otf') format('truetype'),
		url('./assets/fonts/WeissenhofGrotesk-Bold.otf') format('truetype');
	font-weight: normal, bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src:
		local('Montserrat'),
		url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('woff2');
	font-weight: 100 900; /* weight range the font supports */
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter'),
		url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('woff2');
	font-weight: 100 900; /* weight range the font supports */
}

body {
	margin: 0;
	font-family: var(--paragraph-font, Avenir, sans-serif);
	letter-spacing: var(--letter-spacing, normal);
	font-size: 15px;
	line-height: var(--line-height, 20px);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
:after,
:before {
	box-sizing: inherit;
}

h1,
h2 {
	font-family: var(--header-font, var(--paragraph-font, Avenir, sans-serif));
	font-size: 15px;
	margin: 0;
}

.quill > .ql-toolbar,
.quill > .ql-container {
	border: 1px solid #777 !important;
}
.quill > .ql-container {
	border-top: none !important;
}

// TODO: move these to shared bit.dev component rather than copy-paste
/* CSS VARIABLES */
html {
	--FF-COLOR-SKY: rgb(70, 189, 255);
	--FF-COLOR-ACME-BLUE: rgb(34, 111, 215);
	--FF-COLOR-BRIGHT-VIOLET: rgb(104, 54, 225);

	--FF-COLOR-BLACK: rgb(0, 0, 0);
	--FF-COLOR-COAL: rgb(51, 51, 51);
	--FF-COLOR-NICKEL: rgb(102, 102, 102);
	--FF-COLOR-MOON: rgb(153, 153, 153);
	--FF-COLOR-SILVER: rgb(204, 204, 204);
	--FF-COLOR-SNOW: rgb(244, 244, 244);

	--FF-COLOR-SUCCESS: rgb(46, 204, 113);
	--FF-COLOR-SUCCESS-RGB: 46, 204, 113;
	--FF-COLOR-FAILURE: rgb(231, 76, 60);
	--FF-COLOR-FAILURE-RGB: 231, 76, 60;
	--FF-COLOR-WARNING: rgb(221, 174, 0);
	--FF-COLOR-WARNING-RGB: 221, 174, 0;

	--FF-COLOR-SKY-TRANSPARENT: rgba(54, 159, 255, 0.4);

	--FF-COLOR-SKY-CONTRAST: white;
	--FF-COLOR-ACME-BLUE-CONTRAST: white;

	--FF-COLOR-PRIMARY: var(--FF-COLOR-SKY);

	--FF-COLOR-SECONDARY: var(--FF-COLOR-ACME-BLUE);
	--FF-COLOR-SECONDARY-SHADE: rgb(0, 10, 25);
	--FF-COLOR-SECONDARY-TINT: rgb(230, 239, 255);

	--FF-COLOR-TERTIARY: var(--FF-COLOR-BRIGHT-VIOLET);
	--FF-COLOR-TERTIARY-SHADE: rgb(52, 27, 112);
	--FF-COLOR-TERTIARY-TINT: rgb(240, 235, 252);

	--FF-COLOR-TEXT-PRIMARY: rgb(0, 0, 0);
	--FF-COLOR-TEXT-SECONDARY: rgba(0, 0, 0, 0.85);
	--FF-COLOR-TEXT-TERTIARY: rgba(0, 0, 0, 0.6);
	--FF-COLOR-TEXT-QUATERNARY: rgba(0, 0, 0, 0.3);

	//--FF-COLOR-BACKGROUND-PRIMARY: rgba(255, 255, 255, 0.75);
	//--FF-COLOR-BACKGROUND-SECONDARY: rgb(243 214 214 / 48%);

	--FF-COLOR-PRIMARY-BUTTON: var(--FF-COLOR-PRIMARY);

	--FF-BORDER-RADIUS: 3px;

	--FF-COLOR-NAVIGATION-TINT: var(--FF-COLOR-SKY);

	--nav-header-height: 50px;
	--root-margin: 20px; // Margin around main window
	--footer-height: 26px;

	@media screen and (max-width: 800px) {
		--nav-header-height: 60px;
		--root-margin: 0px;
	}
}
button {
	display: inline-block;

	user-select: none;
	cursor: default;

	appearance: none;
	border: none;

	outline: none;

	font-size: 14px;
}
.isHidden {
	display: none !important;
}

// Required for date/time widget
thead tr th {
	text-align: left;
	margin-top: 10px;
	font-weight: normal;
	margin-bottom: 5px;

	input {
		max-width: 12ch;
	}
}
th {
	font-weight: bold !important;
}
.react-datepicker-wrapper input.date,
.react-datepicker-wrapper input.time {
	width: 12ch !important;
	padding: 9px !important;
}

.react-datepicker__navigation {
	width: 0 !important;
	height: 0 !important;
}

.quill {
	.ql-editor {
		padding: 8px;
		* {
			font-family: var(--paragraph-font, Avenir, sans-serif);
			color: black;
		}
		p {
			font-size: 15px;
			margin-bottom: 10px;
		}
	}
}

.btn--lang-select {
	width: 30px;
	vertical-align: top;
	cursor: pointer;
	background: transparent;
	margin-top: 10px;
}

// Twitter emojis
.emoji {
	display: inline-block;
	width: auto;
	height: 1em;
	vertical-align: -0.125em;
}

.inlineMarkdown {
	display: inline;
	* {
		display: inline;
	}
}
