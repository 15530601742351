.calendarWidget {
  // Reverse order of single dates and pattern date widgets
  > div:first-child > div:first-child > div:first-child {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  // Fix styling inconsistency in imported component
  // TODO: fix properly in imported component
  table > tbody > tr > td:nth-child(2) > div > div:last-child {
    margin: 5px;
  }
}
