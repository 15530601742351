p,
label,
ul,
li,
span:not(button span) {
	color: var(--text-color, #181818);
}

.main {
	max-width: 800px;
	margin: auto;
	padding: 15px;

	section > div > div > label {
		font-weight: bold;
		display: block;
		padding: 5px 5px 0;
		margin-top: 10px;
	}
	section > div > div > label:first-child {
		margin-top: 0;
	}

	section > div > div > p {
		padding: 5px;
	}

	input[type='text'].locationLabelInput {
		margin-bottom: 10px;
	}

	input[type='text'],
	input[type='url'],
	textarea {
		width: 90%;
		padding: 5px;
		margin: 5px;
		font-family: inherit;
		font-size: inherit;
	}
	.quillContainer {
		margin: 5px;
		width: calc(90% + 12px);
		font-family: inherit;
		font-size: inherit;
	}
	.buttonContainer {
		text-align: right;
		display: flex;
		justify-content: space-between;

		span {
			margin-right: 7px;
			font-style: italic;
			color: red;
			font-weight: bold;
			align-self: center;
		}

		input[type='submit'], button {
			font-weight: bold;
			color: #fff;
			background-color: var(--FF-COLOR-PRIMARY);
			border: none;
			padding: 10px;
			cursor: pointer;
			&[disabled] {
				background-color: #d3d3d3;
				cursor: not-allowed;
			}
		}
		button {
			margin-right: 5px;
		}

		input[type='submit'] {
			background-color: #449d44;
			&:hover:not([disabled]) {
				background-color: #299b29;
			}
		}
	}
}

header {
	text-align: right;
}

section {
	margin-bottom: 20px;
	background-color: #fff;
	border: var(--section-border, 1px solid #000);
	border-radius: var(--border-radius, 10px);
	box-shadow: var(--section-box-shadow, 0 2px 4px rgba(0, 0, 0, 0.15));
}

section .heading {
	//border-color: #000000;
	border-radius: var(--border-radius, 10px) var(--border-radius, 10px) 0 0;
	color: var(--header-text-color, #ffffff) !important;
	font-size: var(--header-font-size, 16px);
	font-weight: bolder;
	padding: var(--header-padding-vertical, 10px)
		var(--header-padding-horizontal, 15px);
	background-color: var(--header-bg-color, var(--brand-color, #9c9c9c));

	//border-bottom: 1px solid #000;
}
section .body {
	padding: var(--section-padding-vertical, 15px)
		var(--section-padding-horizontal, 15px);

	background-color: var(--section-bg-color, initial);

	p {
		margin: 0 0 10px;

		&.categoryDescription {
			margin-bottom: 0;
			font-style: italic;
		}
	}
}

.logo {
	text-align: right;
	margin-top: 0px;
	height: 50px;
	margin-bottom: 20px;
}

.submitSuccess {
	background-color: palegreen;
	color: black;
	padding: 10px;
	margin: 10px;
}

.eventLocationInfoBox {
	background-color: lightblue;
	//color: black;
	padding: 10px;
	margin: 10px 0 !important;
	font-style: italic;
	max-width: 700px;
}

.mediaWidgetWrapper > * {
	overflow-y: hidden;
	label {
		display: block;
		margin: 8px 0 4px;
	}
	input {
		border: none;
		padding: 8px;
		font-size: 16px;
	}
}

.toggleLocationSelectionTypeButton {
	padding: 5px 10px;
	margin: 5px 0 0 5px;
	background-color: var(--FF-COLOR-SECONDARY);
	color: var(--FF-COLOR-SNOW);
	border-radius: var(--FF-BORDER-RADIUS);
	border: none;
	cursor: pointer;
}

.formError {
	color: var(--FF-COLOR-FAILURE);
	text-align: right;
	margin-right: 8%;
	padding-bottom: 5px;
	font-weight: bold;
}

.locationSelectionWidget {
	margin: 5px;
}
.addressWidget {
	font-weight: bold;
}

// Fix for non-styled Address Widget by default
:global .ff-address-widget {
	font-weight: bold;
	label {
		padding: 5px;
		display: block;
		padding-bottom: 0;
	}
	input {
		width: 90%;
		padding: 5px;
		margin: 5px;
		font-family: inherit;
		font-size: inherit;
	}
}
input[name^='priceElement.priceValue'] {
	width: 10ch !important;
	padding-left: 2ch !important;
}

.progressBar > div {
	border: var(--section-border, 1px solid #000);
}

// Quick fix for wrongly sized delete button
:global .ff-delete-all {
	max-width: 15px !important;
}

.amsGarland {
	width: 100%;
	position: absolute;
	top: -2%;
	left: -10%;
	transform: rotate(-13deg);
	img {
		width: 100%;
		height: 100%;
	}
	@media (max-width: 630px) {
		width: 160%;
		top: -3.5%;
	}
	animation: garlandAnimation infinite 20s ease-in-out, garlandAnimationIntro 1s ease-out;
}

@keyframes garlandAnimation {
	0% {
		filter: hue-rotate(0deg);
	}
	100% {
		filter: hue-rotate(360deg);
	}
}
@keyframes garlandAnimationIntro {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
